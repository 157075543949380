import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { CreateNewsflashPage, DetailNewsflashPage, NewsflashesPage, EditNewsflashPage } from '../pages';

export const NewsflashRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.settings.newsflashes.base}>
      <NewsflashesPage />
    </Route>
    <Route exact path={appUrls.settings.newsflashes.create}>
      <CreateNewsflashPage />
    </Route>
    <Route exact path={appUrls.settings.newsflashes.edit()}>
      <EditNewsflashPage />
    </Route>
    <Route exact path={appUrls.settings.newsflashes.detail()}>
      <DetailNewsflashPage />
    </Route>
  </Switch>
);
