import { Grid, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, PanelAction } from '../../../../../common';
import { Header } from '../../../../../common/components/layout/Header.component';
import { PanelContent } from '../../../../../common/components/panel/panel-content.component';
import { appUrls } from '../../../../../common/config/url.constants';
import { translate } from '../../../../../common/translations/translate';
import { DateFormat, INewsflashAclActions, IRouterWithId } from '../../../../../types';
import { Auth } from '../../../../atoms';
import { useFormStyles } from '../../../../../style/form.styles';
import { NewsflashActions } from '../../../../../store/actions';
import { getNewsflash } from '../../../../../store/selectors/newsflash.selectors';
import { Loader } from '../../../../../common/components/layout/loader.component';
import { LabelValue } from '../../../../../common/components/layout/LabelValue.component';
import moment from 'moment';

export const DetailNewsflashPage: FC = () => {
  const C = useFormStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const newsflash = useSelector(getNewsflash(id));
  const history = useHistory();

  useEffect(() => {
    dispatch(NewsflashActions.fetch(id));
  }, [dispatch, id]);

  const onClick = () => history.push(appUrls.settings.newsflashes.edit(id));

  return newsflash ? (
    <Auth acl={INewsflashAclActions.manageNewsflashes} showUnauthorizedPage>
      <Header backButtonUrl={appUrls.settings.newsflashes.base}>
        {translate('settings.newsflashes.detailTitle', { id })}
      </Header>
      <Container className={C.container} data-testid="permitConditionInfo">
        <Paper square>
          <PanelContent title={translate('settings.newsflashes.details')}>
            <Auth acl={INewsflashAclActions.manageNewsflashes}>
              <PanelAction icon={<Edit />} onClick={onClick} data-testid="editButton" />
            </Auth>
            <Grid container spacing={2} className={C.grid}>
              <LabelValue label={translate('settings.newsflashes.columns.id')}>{newsflash.id}</LabelValue>
              <LabelValue label={translate('settings.newsflashes.columns.title')}>{newsflash.title}</LabelValue>
              <LabelValue label={translate('settings.newsflashes.columns.message')}>{newsflash.message}</LabelValue>
              <LabelValue label={translate('settings.newsflashes.columns.level')}>
                {translate(`settings.newsflashes.level.${newsflash.level}`)}
              </LabelValue>
              <LabelValue label={translate('settings.newsflashes.columns.type')}>{newsflash.type}</LabelValue>
              <LabelValue label={translate('settings.newsflashes.columns.createdAt')}>
                {moment(newsflash.createdAt).format(DateFormat.date)}
              </LabelValue>
              <LabelValue label={translate('settings.newsflashes.columns.updatedAt')}>
                {moment(newsflash.updatedAt).format(DateFormat.date)}
              </LabelValue>
            </Grid>
          </PanelContent>
        </Paper>
      </Container>
    </Auth>
  ) : (
    <Loader />
  );
};
