import AddIcon from '@material-ui/icons/Add';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { COLORS, Container, FormattedDate, IPaging, ISorting } from '../../../../../common';
import { Button } from '../../../../../common/components/buttons/asign-button-extensions';
import { GenericTablePanel } from '../../../../../common/components/table/GenericTablePanel.component';
import { translate } from '../../../../../common/translations/translate';
import { useGeneralStyles } from '../../../../../style/generalStyles';
import { INewsflash, INewsflashAclActions, NewsflashLevel } from '../../../../../types';
import { Auth } from '../../../../atoms';
import { useFormStyles } from '../../../../../style/form.styles';
import { useNewsflashes } from '../../../../../hooks/useNewsflashes.hook';
import { NewsflashActions } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { appUrls } from '../../../../../common/config/url.constants';

const useStyles = createUseStyles({
  id: {
    width: '5%',
    overflow: 'auto',
  },
  active: {
    background: COLORS.LIME,
    border: `1px solid ${COLORS.APPLE}`,
    padding: '5px',
  },
  inactive: {
    padding: '5px',
  },
});

export const NewsflashesPage: FC = () => {
  const C = useStyles();
  const M = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    newsflashes,
    table: { sorting, paging },
    loading,
  } = useNewsflashes();

  const onClick = ({ id }: INewsflash) => history.push(appUrls.settings.newsflashes.detail(`${id}`));

  const onChangeSorting = (sorting: ISorting) => dispatch(NewsflashActions.list.fetch({ sorting }));
  const onChangePaging = (paging: IPaging) => dispatch(NewsflashActions.list.fetch({ paging }));

  return (
    <Container>
      <Auth acl={INewsflashAclActions.manageNewsflashes}>
        <Button.Green
          className={M.createButton}
          startIcon={<AddIcon fontSize={'small'} />}
          onClick={() => history.push(appUrls.settings.newsflashes.create)}
        >
          {translate('settings.newsflashes.newNewsflash')}
        </Button.Green>
      </Auth>
      <Auth acl={INewsflashAclActions.manageNewsflashes}>
        <GenericTablePanel
          dataTestId="SgwProjectsListTable"
          labelUnavailable={translate('settings.newsflashes.list.unavailable')}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          loading={loading}
          records={newsflashes}
          title={<div className={G.flexRowSpaceBetween}>{translate('settings.newsflashes.list.title')}</div>}
          columnKey="id"
          columns={[
            {
              label: translate('settings.newsflashes.columns.id'),
              name: 'id',
              sortable: true,
              className: C.id,
            },
            {
              label: translate('settings.newsflashes.columns.title'),
              name: 'title',
              sortable: true,
              renderer: (title: string, { startDate, endDate }) => (
                <div className={moment().isBetween(startDate, endDate, 'day', '[]') ? C.active : C.inactive}>
                  {title}
                </div>
              ),
            },
            {
              label: translate('settings.newsflashes.columns.level'),
              name: 'level',
              sortable: true,
              renderer: (level: NewsflashLevel) => translate(`settings.newsflashes.level.${level}`),
            },
            {
              label: translate('settings.newsflashes.columns.type'),
              name: 'type',
              sortable: true,
            },
            {
              label: translate('settings.newsflashes.columns.startDate'),
              name: 'startDate',
              sortable: true,
              renderer: (startDate: string) => <FormattedDate date={startDate} />,
            },
            {
              label: translate('settings.newsflashes.columns.endDate'),
              name: 'endDate',
              sortable: true,
              renderer: (endDate: string) => <FormattedDate date={endDate} />,
            },
          ]}
        />
      </Auth>
    </Container>
  );
};
