import { IPagedTableState, IWithId } from './generic.types';

export enum NewsflashLevel {
  info = 'info',
  warning = 'warning',
  alert = 'alert',
}

export enum NewsflashType {
  a_sign = 'A-Sign',
  sgw = 'SGW',
}

export interface INewsflash extends IWithId {
  title: string;
  message: string;
  level: NewsflashLevel;
  type: NewsflashType;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface INewsflashState {
  list: IPagedTableState<INewsflash, INewsflashFilter>;
  loading: boolean;
}

export interface INewsflashFilter {
  type?: NewsflashType;
  level?: NewsflashLevel;
}
