import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getNewsflashList, selectLoading, selectNewsflashTable } from '../store/selectors/newsflash.selectors';
import { NewsflashActions } from '../store/actions';

export const useNewsflashes = (fetchAll = false) => {
  const newsflashes = useSelector(getNewsflashList);
  const table = useSelector(selectNewsflashTable);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NewsflashActions.list.fetch({}));
  }, [dispatch, fetchAll]);

  return { newsflashes, table, loading };
};
