import { IManualPlacementIntake } from '../types';
import { useLocation, useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SnackBarActions } from '../store/actions';
import { translate } from '../common/translations/translate';
import { useUpdateManualPlacementMutation } from '../store/api/rtk-query/manualPlacement.api';

export const useManualPlacementMutation = (publicDomainIntakeId: number) => {
  const { id: requestId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const key = useMemo(() => new URLSearchParams(search).get('key')!, [search]);

  const [updateManualPlacement, { error, isSuccess }] = useUpdateManualPlacementMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(SnackBarActions.setSuccess(translate('manualPlacement.saveSucceeded')));
    }
    if (error && 'message' in error && error.message) {
      dispatch(SnackBarActions.setFailure(error.message));
    }
  }, [isSuccess, error, dispatch]);

  return (data: IManualPlacementIntake) => updateManualPlacement({ requestId, publicDomainIntakeId, key, data });
};
