import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../root.state';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';

export const selectAllIds = (store: IRootState) => store.settings.newsflash.list.allIds;
export const selectById = (store: IRootState) => store.settings.newsflash.list.byId;
export const selectNewsflashTable = (store: IRootState) => store.settings.newsflash.list.table;
export const selectLoading = (store: IRootState) => store.settings.newsflash.list.loading;

export const getNewsflashList = createAllEntitiesSelector(selectAllIds, selectById);

export const getPagedApiParams = createPagedApiParamsSelector(selectNewsflashTable);
export const getNewsflash = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));
