import moment from 'moment';
import { IUser } from '../../../common/types/user';
import { IRequest, IRequestFormData, RequestReason, UserType } from '../../../types';
import { validateEmail } from '../../../common/utils/email.util';
import { validateSSN } from '../../../utils';
import { translate } from '../../../common/translations/translate';

export const REQUEST_FORM_DEFAULT_VALUES: IRequestFormData = {
  userType: UserType.civilian,

  // User information
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  ibanNumber: '',
  ssn: '',
  birthday: '',
  companyId: '',
  companyName: '',

  // Address
  street: '',
  streetNumber: '',
  bus: '',
  zipCode: '',
  city: '',
  country: 'BE',

  // Emergency
  emergencyProcedure: false,

  // Event
  eventName: '',

  // Reason
  reason: '',
  needsPlacement: false,
  needsPayment: false,
  comment: '',
  externalReference: '',
  requestCity: '',
  requestZipCode: undefined,
  requestStreet: '',
  requestStreetNumber: '',

  dateFrom: '',
  dateUntil: '',
  onlyOnWeekdays: false,

  timeFrom: '07:00',
  timeUntil: '17:00',
  entireDay: false,
};

export const requestInitialValues = (request?: IRequest, copyRequest = false): IRequestFormData => {
  const values: IRequestFormData = {
    userType: request?.companyId ? UserType.company : REQUEST_FORM_DEFAULT_VALUES.userType,

    // User information
    firstName: request ? request.firstName : REQUEST_FORM_DEFAULT_VALUES.firstName,
    lastName: request ? request.lastName : REQUEST_FORM_DEFAULT_VALUES.lastName,
    emailAddress: request ? request.emailAddress : REQUEST_FORM_DEFAULT_VALUES.emailAddress,
    phoneNumber: request ? request.phoneNumber : REQUEST_FORM_DEFAULT_VALUES.phoneNumber,
    ibanNumber: request ? request.ibanNumber : REQUEST_FORM_DEFAULT_VALUES.ibanNumber,
    ssn: request ? request.ssn : REQUEST_FORM_DEFAULT_VALUES.ssn,
    birthday: request ? request.birthday : REQUEST_FORM_DEFAULT_VALUES.birthday,
    companyId: request ? request.companyId : REQUEST_FORM_DEFAULT_VALUES.companyId,
    companyName: request ? request.companyName : REQUEST_FORM_DEFAULT_VALUES.companyName,
    cluster: request?.cluster && !Array.isArray(request.cluster) ? request.cluster.cluster : undefined,

    // Address
    street: request ? request.street : REQUEST_FORM_DEFAULT_VALUES.street,
    streetNumber: request ? request.streetNumber : REQUEST_FORM_DEFAULT_VALUES.streetNumber,
    bus: request ? request.bus : REQUEST_FORM_DEFAULT_VALUES.bus,
    zipCode: request ? request.zipCode : REQUEST_FORM_DEFAULT_VALUES.zipCode,
    city: request ? request.city : REQUEST_FORM_DEFAULT_VALUES.city,
    country: request ? request.country.country : REQUEST_FORM_DEFAULT_VALUES.country,

    // Emergency
    emergencyProcedure: request ? request.emergencyProcedure : REQUEST_FORM_DEFAULT_VALUES.emergencyProcedure,

    // Event
    eventName: request ? request.eventName : REQUEST_FORM_DEFAULT_VALUES.eventName,

    // Reason
    reason: request ? request.reason.reason : REQUEST_FORM_DEFAULT_VALUES.reason,
    needsPlacement: request ? request.needsPlacement : REQUEST_FORM_DEFAULT_VALUES.needsPayment,
    needsPayment: request ? request.needsPayment : REQUEST_FORM_DEFAULT_VALUES.needsPayment,
    comment: request ? request.comment : REQUEST_FORM_DEFAULT_VALUES.comment,
    externalReference: request ? request.externalReference : REQUEST_FORM_DEFAULT_VALUES.externalReference,
    requestCity: request?.requestAddress?.city ? request.requestAddress?.city : REQUEST_FORM_DEFAULT_VALUES.requestCity,
    requestZipCode: request?.requestAddress?.zipCode
      ? request.requestAddress?.zipCode
      : REQUEST_FORM_DEFAULT_VALUES.requestZipCode,
    requestStreet: request?.requestAddress?.street
      ? request.requestAddress?.street
      : REQUEST_FORM_DEFAULT_VALUES.requestStreet,
    requestStreetNumber: request?.requestAddress?.street
      ? request.requestAddress?.streetNumber
      : REQUEST_FORM_DEFAULT_VALUES.requestStreetNumber,

    dateFrom: request ? request.dateFrom : REQUEST_FORM_DEFAULT_VALUES.dateFrom,
    dateUntil: request ? request.dateUntil : REQUEST_FORM_DEFAULT_VALUES.dateUntil,
    onlyOnWeekdays: request ? request.onlyOnWeekdays : REQUEST_FORM_DEFAULT_VALUES.onlyOnWeekdays,

    timeFrom: request ? request.timeFrom : REQUEST_FORM_DEFAULT_VALUES.timeFrom,
    timeUntil: request ? request.timeUntil : REQUEST_FORM_DEFAULT_VALUES.timeUntil,
    entireDay: request ? request.entireDay : REQUEST_FORM_DEFAULT_VALUES.entireDay,
    preferredMoment: request ? request.preferredMoment : undefined,
  };

  // Locations
  if (copyRequest && request?.publicDomainIntakes) {
    values.publicDomainIntakes = request.publicDomainIntakes.map(({ type, ...intake }) => {
      return {
        type: type.type,
        state: copyRequest ? null : intake.state,
        zipCode: intake.zipCode,
        carFreeZone: intake.carFreeZone?.id,
        carFreeZoneGateEntrance: intake.carFreeZoneGateEntrance?.id || null,
        carFreeZoneGateExit: intake.carFreeZoneGateExit?.id || null,
        city: intake.city,
        street: intake.street,
        streetNumberFrom: intake.streetNumberFrom,
        streetNumberTo: intake.streetNumberTo,
        attachments: [],
        geometry: intake.geometry,
        description: intake.description,
        isNew: true,
        numberOfSigns: intake.numberOfSigns,
      };
    });
  }
  return values;
};

export const userInitialValues = (user: IUser | null): IRequestFormData => ({
  userType: UserType.civilian,

  // User information
  userRequested: user?.id || undefined,
  firstName: user?.firstName || REQUEST_FORM_DEFAULT_VALUES.firstName,
  lastName: user?.lastName || REQUEST_FORM_DEFAULT_VALUES.lastName,
  emailAddress: user?.email || REQUEST_FORM_DEFAULT_VALUES.emailAddress,
  phoneNumber: user?.phoneNumber || REQUEST_FORM_DEFAULT_VALUES.phoneNumber,
  ibanNumber: user?.ibanNumber || REQUEST_FORM_DEFAULT_VALUES.ibanNumber,
  ssn: user?.ssn || REQUEST_FORM_DEFAULT_VALUES.ssn,
  birthday: user?.birthday || REQUEST_FORM_DEFAULT_VALUES.birthday,
  companyId: user?.companyId || REQUEST_FORM_DEFAULT_VALUES.companyId,
  companyName: user?.companyName || REQUEST_FORM_DEFAULT_VALUES.companyName,

  // Address
  street: user?.street || REQUEST_FORM_DEFAULT_VALUES.street,
  streetNumber: user?.streetNumber || REQUEST_FORM_DEFAULT_VALUES.streetNumber,
  bus: user?.bus || REQUEST_FORM_DEFAULT_VALUES.bus,
  zipCode: user?.zipCode || REQUEST_FORM_DEFAULT_VALUES.zipCode,
  city: user?.city || REQUEST_FORM_DEFAULT_VALUES.city,
  country: user?.country ? user?.country.country : REQUEST_FORM_DEFAULT_VALUES.country,

  // Emergency
  emergencyProcedure: REQUEST_FORM_DEFAULT_VALUES.emergencyProcedure,

  // Reason
  reason: REQUEST_FORM_DEFAULT_VALUES.reason,
  needsPlacement: REQUEST_FORM_DEFAULT_VALUES.needsPlacement,
  needsPayment: REQUEST_FORM_DEFAULT_VALUES.needsPayment,
  comment: REQUEST_FORM_DEFAULT_VALUES.comment,
  externalReference: REQUEST_FORM_DEFAULT_VALUES.externalReference,

  dateFrom: REQUEST_FORM_DEFAULT_VALUES.dateFrom,
  dateUntil: REQUEST_FORM_DEFAULT_VALUES.dateUntil,
  onlyOnWeekdays: REQUEST_FORM_DEFAULT_VALUES.onlyOnWeekdays,

  requestCity: REQUEST_FORM_DEFAULT_VALUES.requestCity,
  requestZipCode: REQUEST_FORM_DEFAULT_VALUES.requestZipCode,
  requestStreet: REQUEST_FORM_DEFAULT_VALUES.requestStreet,
  requestStreetNumber: REQUEST_FORM_DEFAULT_VALUES.requestStreetNumber,

  timeFrom: REQUEST_FORM_DEFAULT_VALUES.timeFrom,
  timeUntil: REQUEST_FORM_DEFAULT_VALUES.timeUntil,
  entireDay: REQUEST_FORM_DEFAULT_VALUES.entireDay,
});

export const requestValidateValues = (values: IRequestFormData) => {
  const errors: Partial<IRequestFormData> = {};
  if (!values.firstName) {
    errors.firstName = translate('Requests.Create.Validation.Required');
  }

  if (!values.lastName) {
    errors.lastName = translate('Requests.Create.Validation.Required');
  }

  if (!values.emailAddress) {
    errors.emailAddress = translate('Requests.Create.Validation.Required');
  } else if (!validateEmail(values.emailAddress)) {
    errors.emailAddress = translate('Requests.Create.Validation.InvalidEmail');
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = translate('Requests.Create.Validation.Required');
  }

  if (values.userType === UserType.civilian) {
    if (values.country !== 'BE' && !values.birthday) {
      errors.birthday = translate('Requests.Create.Validation.Required');
    }
  }

  if (values['userType'] === UserType.company) {
    if (!values.companyId) {
      errors.companyId = translate('Requests.Create.Validation.Required');
    }
    if (!values.companyName) {
      errors.companyName = translate('Requests.Create.Validation.Required');
    }
  }

  if (!values.street) {
    errors.street = translate('Requests.Create.Validation.Required');
  }

  if (!values.streetNumber) {
    errors.streetNumber = translate('Requests.Create.Validation.Required');
  }

  if (!values.zipCode) {
    errors.zipCode = translate('Requests.Create.Validation.Required');
  }

  if (!values.city) {
    errors.city = translate('Requests.Create.Validation.Required');
  }

  if (!values.country) {
    errors.country = translate('Requests.Create.Validation.Required');
  }

  if (!values.reason) {
    errors.reason = translate('Requests.Create.Validation.Required');
  }

  if (!values.comment) {
    errors.comment = translate('Requests.Create.Validation.Required');
  }

  if (
    (values.reason === RequestReason.construction_zone || values.reason === RequestReason.utility_request) &&
    (!values.externalReference || !/^[A-Za-z0-9]+$/.test(`${values.externalReference}`))
  ) {
    if (!values.externalReference) {
      errors.externalReference = translate('Requests.Create.Validation.Required');
    } else {
      errors.externalReference = translate('Requests.Create.Validation.InvalidExternalReference');
    }
  }

  if (!values.dateFrom) {
    errors.dateFrom = translate('Requests.Create.Validation.Required');
  }

  if (!values.dateUntil) {
    errors.dateUntil = translate('Requests.Create.Validation.Required');
  }

  if (
    values.preferredMoment &&
    !moment(values.preferredMoment).isBetween(moment(values.dateFrom), moment(values.dateUntil), 'day', '[]') &&
    values.dateFrom &&
    values.dateUntil
  ) {
    errors.preferredMoment = translate('Requests.Create.Validation.InvalidPreferredMoment');
  }

  if (!values.entireDay && moment(values.timeFrom, 'HH:mm').isAfter(moment(values.timeUntil, 'HH:mm'))) {
    errors.timeFrom = translate('Requests.Create.Validation.TimeOrder');
    errors.timeUntil = translate('Requests.Create.Validation.TimeOrder');
  }

  if (values.ssn && !validateSSN(values.ssn)) {
    errors.ssn = translate('Requests.Create.Validation.InvalidSSN');
  }

  return errors;
};
