import { combineReducers } from 'redux';
import { INewsflash } from '../../../types';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { NewsflashActions } from '../../actions';
import { ISortingDirection } from '../../../common';

export const NewsflashReducer = combineReducers({
  list: createPagedTableReducer<INewsflash>({
    fetchAction: NewsflashActions.list.fetch.type,
    addEntitiesActions: [NewsflashActions.list.set.type],
    setAllIdsAction: NewsflashActions.list.set.type,
    setParamsActions: [NewsflashActions.list.fetch.type, NewsflashActions.list.setParams.type],
    initialParamsState: {
      sorting: { direction: ISortingDirection.asc, key: 'id' },
      paging: { page: 1, pageSize: 20, totalPages: 1, totalRecords: 20 },
      filters: {},
    },
  }),
});
