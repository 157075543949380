export enum IBackOfficeAclActions {
  backofficeUser = 'backofficeUser',
}

export enum ICarFreeZoneAclActions {
  editCarFreeZoneMoovTemplate = 'editCarFreeZoneMoovTemplate',
  syncCarFreeZoneGates = 'syncCarFreeZoneGates',
  syncMoveTemplates = 'syncMoveTemplates',
  viewCarFreeZone = 'viewCarFreeZone',
  viewCarFreeZoneList = 'viewCarFreeZoneList',
  viewMoovTemplates = 'viewMoovTemplates',
  viewExemptions = 'viewExemptions',
}

export enum IControlTabAclActions {
  viewControleTab = 'viewControleTab',
}

export enum ICpaAppAclActions {
  cpaAppUser = 'cpaAppUser',
  setSignToUpgradeMode = 'setSignToUpgradeMode',
}

export enum IDriverAppAclActions {
  decoupleModule = 'decoupleModule',
  driverAppUser = 'driverAppUser',
}

export enum IModulesAclActions {
  createModule = 'createModule',
  deleteModule = 'deleteModule',
  editModule = 'editModule',
  reorderModuleBatches = 'reorderModuleBatches',
  viewModule = 'viewModule',
}

export enum INotificationAclActions {
  editNotification = 'editNotification',
  viewNotification = 'viewNotification',
}

export enum IPlanningAclActions {
  editPlanning = 'editPlanning',
  viewPlanning = 'viewPlanning',
}

export enum IRequestAclActions {
  addCfzToRequestLocation = 'addCfzToRequestLocation',
  addLicensePlatesToRequest = 'addLicensePlatesToRequest',
  approveAssignedRequest = 'approveAssignedRequest',
  approveRequestExtension = 'approveRequestExtension',
  assignRequestToOneself = 'assignRequestToOneself',
  assignUtilityRequestToOneself = 'assignUtilityRequestToOneself',
  cancelAssignedRequest = 'cancelAssignedRequest',
  changeRequestPaymentStatus = 'changeRequestPaymentStatus',
  createRequestExtension = 'createRequestExtension',
  createRequestForAnotherUser = 'createRequestForAnotherUser',
  createRequestWithoutLimitations = 'createRequestWithoutLimitations',
  editAssignedRequest = 'editAssignedRequest',
  editRequestDraft = 'editRequestDraft',
  requestAddInternalNote = 'requestAddInternalNote',
  rejectAssignedRequest = 'rejectAssignedRequest',
  rejectRequestExtension = 'rejectRequestExtension',
  submitAssignedRequest = 'submitAssignedRequest',
  transitionRequestToReview = 'transitionRequestToReview',
  viewRequest = 'viewRequest',
}

export enum ISignsAclActions {
  deleteSign = 'deleteSign',
  editLocation = 'editLocation',
  editSign = 'editSign',
  viewLocation = 'viewLocation',
  viewSign = 'viewSign',
  viewSignEvents = 'viewSignEvents',
  editSignEventsBatch = 'editSignEventsBatch',
}

export enum ITeamAclActions {
  editTeam = 'editTeam',
  viewTeam = 'viewTeam',
}

export enum IUserManagementAclActions {
  addEnforcerRole = 'addEnforcerRole',
  addRolesToUser = 'addRolesToUser',
  createUser = 'createUser',
  editUser = 'editUser',
  viewUser = 'viewUser',
}

export enum IUtilityCompanyAclActions {
  createUtilityCompany = 'createUtilityCompany',
  editUtilityCompany = 'editUtilityCompany',
  viewUtilityCompany = 'viewUtilityCompany',
  createRequestWithoutLimitations = 'createRequestWithoutLimitations',
}

export enum IWhitelistAclActions {
  manageWhitelists = 'manageWhitelists',
}

export enum IWorkOrderItemAclActions {
  editWorkOrderItem = 'editWorkOrderItem',
  reorderWorkOrderItems = 'reorderWorkOrderItems',
  viewWorkOrderItem = 'viewWorkOrderItem',
}

export enum IServiceAclActions {
  viewServiceList = 'viewServiceList',
  viewService = 'viewService',
  exportServiceWhitelist = 'exportServiceWhitelist',
  syncAPTR = 'syncAPTR',
  editService = 'editService',
  editServicePincode = 'editServicePincode',
  createService = 'createService',
  viewWhitelistList = 'viewWhitelistList',
  downloadWhitelist = 'downloadWhitelist',
}

export enum ISgwRequestAclActions {
  getSgwTrafficMeasuresForPhaseDetails = 'getSgwTrafficMeasuresForPhaseDetails',
  viewSgwRequestOverview = 'viewSgwRequestOverview',
  viewSgwRequestDetail = 'viewSgwRequestDetail',
  editSgwRequest = 'editSgwRequest',
  editSgwInternalNotes = 'editSgwInternalNotes',
  editSgwCommunicationModule = 'editSgwCommunicationModule',
  sendCommunicationToSgwRequestor = 'sendCommunicationToSgwRequestor',
  sgwRequestAddInternalNote = 'sgwRequestAddInternalNote',
  sgwRequestAssignOneselfAsMainResponsible = 'sgwRequestAssignOneselfAsMainResponsible',
  sgwRequestAssignOneselfAsGisResponsible = 'sgwRequestAssignOneselfAsGisResponsible',
  sgwRequestAssignAnotherPersonAsMainResponsible = 'sgwRequestAssignAnotherPersonAsMainResponsible',
  sgwRequestAssignAnotherPersonAsGisResponsible = 'sgwRequestAssignAnotherPersonAsGisResponsible',
  sgwRequestSetStateToInReview = 'sgwRequestSetStateToInReview',
  sgwRequestSetStateToRejected = 'sgwRequestSetStateToRejected',
  sgwRequestSetStateToPermitGranted = 'sgwRequestSetStateToPermitGranted',
  sgwRequestSetStateToCancelled = 'sgwRequestSetStateToCancelled',
  viewSgwDashboardTiles = 'viewSgwDashboardTiles',
}

export enum ISgwPermitConditionsAclActions {
  viewSgwPermitConditionsOverview = 'viewSgwPermitConditionsOverview',
  createSgwPermitCondition = 'createSgwPermitCondition',
  editSgwPermitCondition = 'editSgwPermitCondition',
  viewSgwPermitConditionDetail = 'viewSgwPermitConditionDetail',
}

export enum ISgwProjectAclActions {
  viewSgwProjectOverview = 'viewSgwProjectOverview',
  createSgwProject = 'createSgwProject',
  editSgwProject = 'editSgwProject',
  deleteSgwProject = 'deleteSgwProject',
  viewSgwProjectDetail = 'viewSgwProjectDetail',
}

export enum ISgwNatureOfWorksAclActions {
  viewSgwNatureOfWorksOverview = 'viewSgwNatureOfWorksOverview',
  createSgwNatureOfWorks = 'createSgwNatureOfWorks',
  editSgwNatureOfWorks = 'editSgwNatureOfWorks',
  deleteSgwNatureOfWorks = 'deleteSgwNatureOfWorks',
  viewSgwNatureOfWorksDetail = 'viewSgwNatureOfWorksDetail',
}

export enum ISgwTrafficMeasureAclActions {
  viewSgwTrafficMeasuresOverview = 'viewSgwTrafficMeasuresOverview',
  createSgwTrafficMeasure = 'createSgwTrafficMeasure',
  editSgwTrafficMeasure = 'editSgwTrafficMeasure',
  deleteSgwTrafficMeasure = 'deleteSgwTrafficMeasure',
  viewSgwTrafficMeasureDetail = 'viewSgwTrafficMeasureDetail',
}

export enum ISgwRequestDetailAclActions {
  viewSgwCommunicationModule = 'viewSgwCommunicationModule',
  editSgwCommunicationModule = 'editSgwCommunicationModule',
  viewSgwInternalNotes = 'viewSgwInternalNotes',
  editSgwInternalNotes = 'editSgwInternalNotes',
  viewSgwPartnerInformation = 'viewSgwPartnerInformation',
  editSgwPartnerInformation = 'editSgwPartnerInformation',
  viewSgwResponsiblePanel = 'viewSgwResponsiblePanel',
  editSgwResponsiblePanel = 'editSgwResponsiblePanel',
  viewSgwGeneralInformation = 'viewSgwGeneralInformation',
  editSgwGeneralInformation = 'editSgwGeneralInformation',
  viewSgwPhaseDetails = 'viewSgwPhaseDetails',
  editSgwPhaseDetails = 'editSgwPhaseDetails',
  viewSgwPermitConditions = 'viewSgwPermitConditions',
  editSgwPermitConditions = 'editSgwPermitConditions',
  viewSgwMap = 'viewSgwMap',
  editSgwMap = 'editSgwMap',
  viewSgwAttachmentPanel = 'viewSgwAttachmentPanel',
  editSgwAttachmentPanel = 'editSgwAttachmentPanel',
  viewAllConflicts = 'viewAllConflicts',
  viewOnlyConflictsOfOwnAdvisingParty = 'viewOnlyConflictsOfOwnAdvisingParty',
  syncSgwConflicts = 'syncSgwConflicts',
  createManualAdviceRequest = 'createManualAdviceRequest',
  assignSgwConflictToAdvisingParty = 'assignSgwConflictToAdvisingParty',
  sendRequestForAdvice = 'sendRequestForAdvice',
  setSgwConflictStateToAwaitingAdvice = 'setSgwConflictStateToAwaitingAdvice',
  setSgwConflictStateToFeedbackGiven = 'setSgwConflictStateToFeedbackGiven',
  setSgwConflictStateToNotApplicable = 'setSgwConflictStateToNotApplicable',
  setSgwConflictStateToConflictResolved = 'setSgwConflictStateToConflictResolved',
  useSgwConflictCommunicationModule = 'useSgwConflictCommunicationModule',
  useSgwConflictRemarksAdvisingParty = 'useSgwConflictRemarksAdvisingParty',
  viewSgwConflictRemarksAdvisingParty = 'viewSgwConflictRemarksAdvisingParty',
  viewSgwCostCalculation = 'viewSgwCostCalculation',
  viewReschedulingRequest = 'viewReschedulingRequest',
  considerReschedulingRequest = 'considerReschedulingRequest',
  rejectReschedulingRequest = 'rejectReschedulingRequest',
  applyReschedulingRequest = 'applyReschedulingRequest',
  updateReschedulingRequest = 'updateReschedulingRequest',
  editSgwCostCalculation = 'editSgwCostCalculation',
  canSendFinalInvoice = 'canSendFinalInvoice',
}

export enum ISgwAdvisingPartiesAclActions {
  viewAdvisingPartiesOverview = 'viewAdvisingPartiesOverview',
  viewSgwAdvisingPartyRequestOverview = 'viewSgwAdvisingPartyRequestOverview',
  createAdvisingParties = 'createAdvisingParties',
  editAdvisingParties = 'editAdvisingParties',
  deleteAdvisingParties = 'deleteAdvisingParties',
  viewAdvisingPartiesDetail = 'viewAdvisingPartiesDetail',
  addUserToAdvisingParty = 'addUserToAdvisingParty',
}

export enum INewsflashAclActions {
  manageNewsflashes = 'manageNewsflashes',
}

export enum ISgwSettings {
  viewSgwSettings = 'viewSgwSettings',
  editSgwSettings = 'editSgwSettings',
}

export type IAclActions =
  | IBackOfficeAclActions
  | ICarFreeZoneAclActions
  | ICpaAppAclActions
  | IDriverAppAclActions
  | IModulesAclActions
  | INotificationAclActions
  | IPlanningAclActions
  | IRequestAclActions
  | IServiceAclActions
  | ISgwAdvisingPartiesAclActions
  | ISgwNatureOfWorksAclActions
  | ISgwPermitConditionsAclActions
  | ISgwProjectAclActions
  | ISgwRequestAclActions
  | ISgwRequestDetailAclActions
  | ISgwSettings
  | ISgwTrafficMeasureAclActions
  | ISignsAclActions
  | ITeamAclActions
  | IUserManagementAclActions
  | IUtilityCompanyAclActions
  | IWhitelistAclActions
  | IWorkOrderItemAclActions
  | INewsflashAclActions;

export type IAclMatrix = {
  [key in keyof IAclActions as string]: boolean;
};
