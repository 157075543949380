import { Actions, TableActions } from '../../common';
import { INewsflash, ISgwProjectsFilter } from '../../types';

class NewsflashActionsClass extends Actions {
  list = new TableActions<INewsflash, ISgwProjectsFilter>(`${this.prefix}.list`);
  fetch = this.createPayloadAction<string>('fetch');
  save = this.createPayloadAction<INewsflash>('save');
}

export const NewsflashActions = new NewsflashActionsClass('newsflash');
