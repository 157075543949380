import { useForm } from 'react-hook-form';
import { IPublicDomainIntakeExtended } from '../../types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getStorageFiles } from '../../store/selectors';
import { useManualPlacementMutation } from '../useManualPlacementMutation.hook';
import { IManualPlacementIntake } from '../../types';

export const useManualPlacementForm = (intake: IPublicDomainIntakeExtended) => {
  const storageFiles = useSelector(getStorageFiles);
  const { comment, attachments, noParkedVehicles, parkedPlates, signsPlacedAtTime, signsPlacedAtDate } = intake;
  const formMethods = useForm<IManualPlacementIntake>({
    defaultValues: {
      comment,
      attachments: attachments?.map(({ fileName }) => `${fileName}`),
      noParkedVehicles,
      parkedPlates,
      signsPlacedAtDate,
      signsPlacedAtTime,
    },
  });
  const updateManualPlacement = useManualPlacementMutation(intake.id);
  const { watch, setValue, getValues, handleSubmit } = formMethods;

  useEffect(() => {
    setValue('attachments', attachments?.map(({ fileName }) => `${fileName}`) || []);
  }, [attachments, setValue]);

  useEffect(() => {
    const attachments = getValues('attachments') || [];
    setValue('attachments', [
      ...(getValues('attachments') || []),
      ...storageFiles
        // only add new + not loading attachments
        .filter(({ loading, uploadId }) => !loading && !attachments.some((fileName) => fileName === uploadId))
        .map(({ uploadId }) => `${uploadId}`),
    ]);
  }, [getValues, setValue, storageFiles]);

  const onSubmit = handleSubmit((values) => {
    updateManualPlacement(values);
  });

  return {
    onSubmit,
    formMethods,
    parkedPlates: watch('parkedPlates') || [],
  };
};
