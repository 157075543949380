import { useLocation, useParams } from 'react-router';
import { useMemo } from 'react';
import { useGetManualPlacementQuery } from '../store/api/rtk-query/manualPlacement.api';

export const useManualPlacementQuery = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const key = useMemo(() => new URLSearchParams(search).get('key')!, [search]);
  const { data: request, isLoading } = useGetManualPlacementQuery({ id, key });

  return { isLoading, request };
};
