import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { NewsflashRoutes } from './Newsflash.routes';
import { RouterMenu } from '../molecules';
import { translate } from '../../common/translations/translate';
import { INewsflashAclActions } from '../../types';
import { Auth } from '../atoms';

export const SettingsRoutes: FC = () => (
  <Auth acl={INewsflashAclActions.manageNewsflashes}>
    <RouterMenu
      tabs={[
        {
          label: translate('settings.newsflashes.title'),
          route: appUrls.settings.newsflashes.base,
        },
      ]}
    />
    <Switch>
      <Route path={appUrls.settings.newsflashes.base}>
        <NewsflashRoutes />
      </Route>
    </Switch>
  </Auth>
);
