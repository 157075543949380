import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendUrl } from '../../../common';

export const baseApi = createApi({
  reducerPath: 'rtk',
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
  }),
  endpoints: () => ({}),
  tagTypes: ['ManualPlacement'],
});
