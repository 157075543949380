import { ConnectedRouter } from 'connected-react-router';
import I18n from 'i18n-js';
import * as React from 'react';
import { Provider } from 'react-redux';
import nl from './common/translations/nl.json';
import { RootComponent } from './root.component';
import { setupInterceptors } from './root.interceptors';
import { history, store } from './root.store';
import './style/index.scss';
import 'typeface-roboto';
import { createRoot } from 'react-dom/client';
import { SentryBoundary } from './SentryBoundary.component';

I18n.defaultLocale = 'nl';
I18n.fallbacks = true;
I18n.translations = { nl };

setupInterceptors(history, store);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SentryBoundary>
        <RootComponent />
      </SentryBoundary>
    </ConnectedRouter>
  </Provider>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(<App />);
