import { Grid, TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Panel, PanelAction } from '../../../common';
import { translate } from '../../../common/translations/translate';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useHistory } from 'react-router';
import { appUrls } from '../../../common/config/url.constants';
import { useFormStyles } from '../../../style/form.styles';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SubmitButton } from '../../atoms';
import { useNewsflashForm } from '../../../hooks/forms/useNewsflashForm.hook';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import moment from 'moment/moment';
import { DateFormat, NewsflashLevel, NewsflashType } from '../../../types';
import { convertDateFormat } from '../../../common/utils/date.util';
import { NewsflashActions } from '../../../store/actions';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';

interface IProps {
  title: string;
}

export const NewsflashForm: FC<IProps> = ({ title }) => {
  const C = useFormStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formMethods, submitNewsflash, id } = useNewsflashForm();
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = formMethods;

  useEffect(() => {
    id && dispatch(NewsflashActions.fetch(id));
  }, [dispatch, id]);

  const goToDetail = () => history.push(appUrls.settings.newsflashes.detail(id));

  const startDateMoment = watch('startDate')
    ? moment(watch('startDate'), DateFormat.reduxStoreDateTimeString)
    : undefined;
  const endDateMoment = watch('endDate') ? moment(watch('endDate'), DateFormat.reduxStoreDateTimeString) : undefined;

  return (
    <FormProvider {...formMethods}>
      <form id="createNewsflash" onSubmit={submitNewsflash} noValidate>
        <Panel title={title}>
          <Visible visible={!!id}>
            <PanelAction icon={<Edit />} onClick={goToDetail} data-testid="editButton" />
          </Visible>
          <Grid container spacing={3} className={C.formGrid}>
            <Grid item xs={12}>
              <TextField
                {...register('title', { required: true })}
                label={translate('settings.newsflashes.columns.title')}
                className={G.fullWidth}
                required
                error={!!errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('message', { required: true })}
                label={translate('settings.newsflashes.columns.message')}
                className={G.fullWidth}
                required
                error={!!errors.message}
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <SingleSelectV2Component
                placeholder={translate('settings.newsflashes.columns.type')}
                {...register('type', {
                  required: { value: true, message: translate('settings.newsflashes.columns.type') },
                })}
                error={!!errors.type}
                onChange={(e) => setValue('type', e.target.value as NewsflashType)}
                menuItems={Object.values(NewsflashType).map((type) => ({ value: type, label: type }))}
                value={watch('type')}
              />
            </Grid>
            <Grid item xs={12}>
              <SingleSelectV2Component
                placeholder={translate('settings.newsflashes.columns.level')}
                {...register('level', {
                  required: { value: true, message: translate('settings.newsflashes.columns.level') },
                })}
                error={!!errors.level}
                onChange={(e) => setValue('level', e.target.value as NewsflashLevel)}
                menuItems={Object.values(NewsflashLevel).map((level) => ({
                  value: level,
                  label: translate(`settings.newsflashes.level.${level}`),
                }))}
                value={watch('level')}
              />
            </Grid>
            <Grid item xs={12}>
              <AsignDatepicker
                label={translate('settings.newsflashes.columns.startDate')}
                {...register('startDate', { required: true })}
                value={startDateMoment}
                onChange={(value) =>
                  setValue(
                    'startDate',
                    convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                  )
                }
                required
                maxDate={endDateMoment}
              />
            </Grid>
            <Grid item xs={12}>
              <AsignDatepicker
                label={translate('settings.newsflashes.columns.endDate')}
                {...register('endDate', { required: true })}
                value={endDateMoment}
                onChange={(value) =>
                  setValue(
                    'endDate',
                    convertDateFormat(value, DateFormat.reduxStoreDateTimeString, DateFormat.reduxStoreDateString),
                  )
                }
                required
                minDate={startDateMoment}
              />
            </Grid>
          </Grid>
        </Panel>
        <SubmitButton formId="createNewsflash">{translate('settings.newsflashes.save')}</SubmitButton>
      </form>
    </FormProvider>
  );
};
