import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';
import { translate } from '../../translations/translate';
import { FC } from 'react';

interface IProps {
  id: string;
  label: string;
  data: any[];
  value: any[];
  valueChange: (value: any[]) => void;
  valueProp?: string;
  displayProp?: string;
  inputLabelProp?: string;
  keyProp?: string;
  placeholder?: string;
}

export const MultiSelect: FC<IProps> = ({
  id,
  label,
  displayProp,
  data,
  valueProp,
  value,
  valueChange,
  inputLabelProp,
  keyProp,
  placeholder,
}) => {
  const getSelectValue = () => {
    return value.length ? value : placeholder ? [placeholder] : [];
  };

  const renderValue = () => {
    return value.length
      ? value
          .map((value) => (inputLabelProp ? getInputLabelValue(getData(value)) : getDisplayValue(getData(value))))
          .join(', ')
      : placeholder;
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (e.target.value.indexOf('select-all') >= 0) {
      valueChange(data.map(getValue));
    } else if (e.target.value.indexOf('deselect-all') >= 0) {
      valueChange([]);
    } else {
      const value = e.target.value.filter((item: string) => item !== placeholder || !placeholder);
      valueChange(value);
    }
  };

  const getDisplayValue = (value: any) => {
    return displayProp ? value?.[displayProp] : value;
  };

  const getInputLabelValue = (value: any) => {
    return inputLabelProp ? value?.[inputLabelProp] : value;
  };

  const getKeyValue = (value: any) => (keyProp ? value?.[keyProp] : value);
  const getValue = (value: any) => (valueProp ? value?.[valueProp] : value);
  const getData = (value: any) => (valueProp ? data.find((data: any) => data[valueProp] === value) : value);

  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor={id} shrink={placeholder ? true : undefined}>
        {label}
      </InputLabel>
      <Select
        multiple={true}
        value={getSelectValue()}
        onChange={handleChange}
        input={<Input id={id} />}
        renderValue={renderValue}
        className="select-component"
      >
        <MenuItem style={{ padding: 10 }} key="select-all" value="select-all">
          <ListItemText style={{ padding: 0 }} primary={translate('SelectAll')} />
        </MenuItem>
        <MenuItem style={{ padding: 10 }} key="deselect-all" value="deselect-all">
          <ListItemText style={{ padding: 0 }} primary={translate('DeselectAll')} />
        </MenuItem>
        {data.map((data: any) => (
          <MenuItem style={{ padding: 10 }} key={getKeyValue(data)} value={getValue(data)}>
            <Checkbox color="primary" checked={value.indexOf(getValue(data)) > -1} />
            <ListItemText style={{ padding: 0 }} primary={getDisplayValue(data)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
