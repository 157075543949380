import { useForm } from 'react-hook-form';
import { IRouterWithId, INewsflash } from '../../types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewsflashActions } from '../../store/actions';
import { useParams } from 'react-router';
import { getNewsflash } from '../../store/selectors/newsflash.selectors';

export const useNewsflashForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const initialValues = useSelector(getNewsflash(id));

  const formMethods = useForm<INewsflash>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...initialValues },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const _onSubmit = useCallback(
    (values: INewsflash) => {
      dispatch(NewsflashActions.save(values));
    },
    [dispatch],
  );

  const submitNewsflash = handleSubmit(_onSubmit);

  return { formMethods, submitNewsflash, id };
};
