import '@babel/polyfill'; // https://mysticcoders.com/blog/fixing-regenerator-runtime-error-integrating-redux-saga
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { reducer } from './root.reducer';
import { rootSaga } from './root.saga';
import { captureException, createReduxEnhancer } from '@sentry/react';
import { appUrls } from './common/config/url.constants';
import { IS_PRODUCTION } from './common/config/environment';
import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from './store/api/rtk-query';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    captureException(new Error(error.message));
    window.location.href = appUrls.error;
  },
});

//state send to sentry is currently set to null, can be adjusted later. Be careful with sensitive data!
const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: (state) => (IS_PRODUCTION ? null : state),
});

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddlewareConfig = routerMiddleware(history);

export const store = configureStore({
  reducer: reducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware, routerMiddlewareConfig, baseApi.middleware),
  devTools: !IS_PRODUCTION,
  enhancers: (defaultEnhancers) => defaultEnhancers.concat(sentryReduxEnhancer),
});

sagaMiddleware.run(rootSaga as any);
