import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { appUrls } from '../../common/config/url.constants';
import { AdvisingPartiesPage, CreateAdvisingPartyPage, DetailAdvisingPartyPage, EditAdvisingPartyPage } from '../pages';

export const AdvisingPartiesRoutes: FC = () => (
  <Switch>
    <Route exact path={appUrls.sgw.manage.advisingParties.base}>
      <AdvisingPartiesPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.advisingParties.create}>
      <CreateAdvisingPartyPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.advisingParties.detail()}>
      <DetailAdvisingPartyPage />
    </Route>
    <Route exact path={appUrls.sgw.manage.advisingParties.edit()}>
      <EditAdvisingPartyPage />
    </Route>
  </Switch>
);
