import { Grid, makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from '../../../common';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { Autocomplete } from '../../../common/components/form-fields/autocomplete.component';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { QuickFilter } from '../../../request/components/overview/quick-filter.component';
import { Disableable } from '../../../common/components/layout/Disableable.component';
import { UserActions } from '../../../common/store/user/user.actions';
import { getAdmins } from '../../../common/store/user/user.selectors';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { SearchTextComponent } from '../../../request/components/overview/search-text-component';
import { SgwRequestActions } from '../../../store/actions';
import { selectTable } from '../../../store/selectors/sgwRequest.selectors';
import {
  ConflictGroupState,
  DateFormat,
  ISgwRequestsFilter,
  MessageState,
  SgwRequestSearchField,
  WorkType,
} from '../../../types';
import { useProjects } from '../../../hooks/useProjects.hook';
import { useAtom } from 'jotai';
import { ATOMS } from '../../../common/config/atom.constants';
import { useRequestMetadata } from '../../../hooks';
import { FeedbackIcon } from '../../../request/components/icons/feedback-icon';
import { Icon } from '../../../common/components/layout/icon.component';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  clear: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  datesContainer: {
    width: '80%',
    margin: 0,
  },
});

interface IProps {
  userRequested?: number;
}

export const SgwRequestListFilters: FC<IProps> = ({ userRequested }: IProps) => {
  const atoms = useMemo(() => (!!userRequested ? ATOMS.users.sgwRequest : ATOMS.sgwRequest.overview), [userRequested]);
  const [searchField, setSearchField] = useAtom(atoms.searchFieldAtom);
  const [searchValue, setSearchValue] = useAtom(atoms.searchValueAtom);
  const [filters, setFilters] = useAtom(atoms.filtersAtom);
  const C = useStyles();
  const dispatch = useDispatch();
  const { projects } = useProjects(true);
  const admins = useSelector(getAdmins);
  const { filters: _filters } = useSelector(selectTable);

  const { activeFrom: from, activeUntil: until, userResponsible } = filters;
  const momentFrom = from ? moment(from) : undefined;
  const momentUntil = until ? moment(until) : undefined;
  const metadata = useRequestMetadata();
  const workTypeOptions = Object.values(WorkType).map((workType) => ({
    name: translateIgnoreTS(`sgw.requests.workTypes.${workType}`),
    id: workType,
  }));

  const sgwRequestStateOptions =
    metadata?.sgwStates.map((requestState) => ({
      name: requestState.name,
      id: requestState.state,
    })) || [];

  useEffect(() => {
    dispatch(UserActions.fetchAdminList());
    onFetchRequests({
      activeFrom: momentFrom?.format(DateFormat.reduxStoreDateString),
      activeUntil: momentUntil?.format(DateFormat.reduxStoreDateString),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilters(_filters);
  }, [_filters, setFilters]);

  const changeSearchField = (e: ChangeEvent<any>) => {
    setSearchField(e.target.value);
    setSearchValue('');
  };

  const changeSearchValue = (e: ChangeEvent<any>) => setSearchValue(e.target.value);

  const isValidSearchField = () => {
    switch (searchField) {
      case SgwRequestSearchField.contactName:
      case SgwRequestSearchField.streetName:
        return searchValue.length === 0 || searchValue.length > 2;
      case SgwRequestSearchField.zipCode:
        return searchValue.length === 0 || searchValue.length === 4;
      case SgwRequestSearchField.referenceId:
        return searchValue.length === 0 || searchValue.length === 13;
      default:
        return true;
    }
  };

  const onSearch = () => onFetchRequests({ [searchField]: searchValue });

  const prepareFilterValues = (newFilterValues: ISgwRequestsFilter) => {
    // Set all search fields to undefined except for the currently selected search field
    Object.values(SgwRequestSearchField).forEach((field) => {
      newFilterValues[field] = undefined;
    });
    newFilterValues[searchField] = searchValue;

    return newFilterValues;
  };

  const onFetchRequests = (newFilterValues: ISgwRequestsFilter) => {
    const preparedFilterValues = prepareFilterValues(newFilterValues);

    dispatch(
      SgwRequestActions.list.fetch({
        filters: {
          ...filters,
          ...preparedFilterValues,
          userRequested,
        },
      }),
    );
  };

  const resetFilters = () => {
    dispatch(
      SgwRequestActions.list.fetch({
        filters: {
          contactName: undefined,
          activeFrom: !userRequested
            ? moment().subtract(1, 'month').format(DateFormat.reduxStoreDateString)
            : undefined,
          activeUntil: !userRequested ? moment().add(1, 'year').format(DateFormat.reduxStoreDateString) : undefined,
          projectIds: [],
          referenceId: undefined,
          streetName: undefined,
          state: undefined,
          typeOfWork: [],
          userResponsible: undefined,
          userRequested,
          zipCode: undefined,
          filterOnlyOnReferenceId: false,
          messageState: undefined,
        },
      }),
    );
    setSearchField(SgwRequestSearchField.contactName);
    setSearchValue('');
  };

  const showToggleBypass = () =>
    isValidSearchField() && searchField === SgwRequestSearchField.referenceId && searchValue.length !== 0;

  const disabledFilters = !!filters.filterOnlyOnReferenceId && searchField === SgwRequestSearchField.referenceId;
  return (
    <>
      <Grid container spacing={1} className={C.container}>
        <Grid item xs={2}>
          <SearchTextComponent<SgwRequestSearchField>
            allSearchFields={[
              { value: SgwRequestSearchField.contactName, label: translate('sgw.requests.dashboard.search.name') },
              { value: SgwRequestSearchField.streetName, label: translate('sgw.requests.dashboard.search.address') },
              {
                value: SgwRequestSearchField.referenceId,
                label: translate('sgw.requests.dashboard.search.reference'),
              },
              { value: SgwRequestSearchField.zipCode, label: translate('sgw.requests.dashboard.search.zipCode') },
            ]}
            searchField={searchField}
            updateSearchField={changeSearchField}
            updateSearchValue={changeSearchValue}
            searchValue={searchValue}
            title={translate('filterOn')}
            error={!isValidSearchField()}
            saveSearchValue={onSearch}
          />
        </Grid>
        <Grid item xs={2}>
          <Disableable disabled={disabledFilters}>
            <Autocomplete<number>
              placeholder={translate('sgw.requests.dashboard.search.userResponsible')}
              menuItems={admins}
              value={userResponsible}
              onChange={(userResponsible?: number) => onFetchRequests({ userResponsible })}
              elipsisChars={25}
              data-testid="userResponsibleField"
            />
          </Disableable>
        </Grid>
        <Grid item xs={1}>
          {!!projects?.length && (
            <Disableable disabled={disabledFilters}>
              <MultiSelect
                data={projects}
                value={filters.projectIds || []}
                valueChange={(projectIds: string[]) => onFetchRequests({ projectIds })}
                valueProp="id"
                keyProp="id"
                displayProp="name"
                id="projectNameFilter"
                label={translate('sgw.requests.dashboard.search.project')}
                data-testid="projectField"
              />
            </Disableable>
          )}
        </Grid>
        <Grid item xs={1}>
          <Disableable disabled={disabledFilters}>
            <MultiSelect
              data={workTypeOptions}
              value={filters.typeOfWork || []}
              valueChange={(typeOfWork: string[]) => onFetchRequests({ typeOfWork })}
              valueProp="id"
              keyProp="id"
              displayProp="name"
              id="typeOfWorkFilter"
              label={translate('sgw.requests.dashboard.search.type')}
              data-testid="workTypeField"
            />
          </Disableable>
        </Grid>
        <Grid item xs={1}>
          {!!sgwRequestStateOptions.length && (
            <Disableable disabled={disabledFilters}>
              <MultiSelect
                data={sgwRequestStateOptions}
                value={filters.state || []}
                valueChange={(state: string[]) => onFetchRequests({ state })}
                valueProp="id"
                keyProp="id"
                displayProp="name"
                id="requestStateFilter"
                label={translate('sgw.requests.dashboard.search.state')}
                data-testid="requestStateField"
              />
            </Disableable>
          )}
        </Grid>
        <Grid item xs={1}>
          <Disableable disabled={disabledFilters}>
            <MultiSelect
              data={[
                {
                  id: MessageState.feedback_received,
                  icon: <FeedbackIcon messageState={MessageState.feedback_received} />,
                  inputLabel: translate('feedbackReceived'),
                },
                {
                  id: MessageState.awaiting_feedback,
                  icon: <FeedbackIcon messageState={MessageState.awaiting_feedback} />,
                  inputLabel: translate('awaitingFeedback'),
                },
                {
                  id: ConflictGroupState.feedbackGiven,
                  icon: <Icon.FeedbackGiven />,
                  inputLabel: translate('icons.alt.feedbackGiven'),
                },
              ]}
              value={[
                ...(filters.messageState || []),
                ...(filters.conflictsWithFeedback ? [ConflictGroupState.feedbackGiven] : []),
              ]}
              valueChange={(messageState: (MessageState | ConflictGroupState.feedbackGiven)[]) => {
                onFetchRequests({
                  messageState: messageState.filter((state) =>
                    Object.values(MessageState).includes(state as MessageState),
                  ) as MessageState[],
                  conflictsWithFeedback: messageState.includes(ConflictGroupState.feedbackGiven),
                });
              }}
              valueProp="id"
              keyProp="id"
              displayProp="icon"
              inputLabelProp="inputLabel"
              id="requestMessageStateFilter"
              label={translate('sgw.requests.dashboard.search.messageState')}
              data-testid="requestMessageStateField"
            />
          </Disableable>
        </Grid>
        <Grid item xs={1}>
          <Disableable disabled={disabledFilters}>
            <AsignDatepicker
              label={translate('sgw.requests.dashboard.search.from')}
              value={momentFrom}
              onChange={(selectedDate: Moment) =>
                onFetchRequests({ activeFrom: selectedDate.format(DateFormat.reduxStoreDateString) })
              }
              maxDate={momentUntil}
              options={{ popperPlacement: 'bottom-start', 'data-testid': 'fromDateField' }}
            />
          </Disableable>
        </Grid>
        <Grid item xs={1}>
          <Disableable disabled={disabledFilters}>
            <AsignDatepicker
              label={translate('sgw.requests.dashboard.search.to')}
              value={momentUntil}
              onChange={(selectedDate: Moment) =>
                onFetchRequests({ activeUntil: selectedDate.format(DateFormat.reduxStoreDateString) })
              }
              minDate={momentFrom}
              options={{ popperPlacement: 'bottom-start', 'data-testid': 'untilDateField' }}
            />
          </Disableable>
        </Grid>
        <Grid item xs={1} className={C.clear}>
          <ClearFiltersFab onClick={resetFilters} />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={C.container}>
        <Grid item xs={2}>
          <Visible visible={showToggleBypass()}>
            <QuickFilter
              checked={filters.filterOnlyOnReferenceId}
              onChange={(e) => onFetchRequests({ filterOnlyOnReferenceId: e.target.checked })}
              label={translate('Requests.Filter.SearchAllRequest')}
            />
          </Visible>
        </Grid>
      </Grid>
    </>
  );
};
