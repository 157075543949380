import React, { FC } from 'react';
import { Auth } from '../../../../atoms';
import { INewsflashAclActions } from '../../../../../types';
import { Header } from '../../../../../common/components/layout/Header.component';
import { appUrls } from '../../../../../common/config/url.constants';
import { translate } from '../../../../../common/translations/translate';
import { Container } from '../../../../../common';
import { useFormStyles } from '../../../../../style/form.styles';
import { NewsflashForm } from '../../../../forms/NewsflashForm/NewsflashForm.component';

export const EditNewsflashPage: FC = () => (
  <Auth acl={INewsflashAclActions.manageNewsflashes} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.settings.newsflashes.base}>{translate('settings.newsflashes.title')}</Header>
    <Container className={useFormStyles().container}>
      <NewsflashForm title={translate('settings.newsflashes.details')} />
    </Container>
  </Auth>
);
