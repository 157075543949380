import { combineReducers } from 'redux';
import { SgwSettingsActions } from '../../actions';
import { ISetting } from '../../../types';
import { createPagedTableReducer } from '../pagedTable.reducer';
import { initialSgwSettingsState } from '../../state';

export const settingsReducer = combineReducers({
  list: createPagedTableReducer<ISetting>({
    fetchAction: SgwSettingsActions.list.fetch.type,
    addEntitiesActions: [SgwSettingsActions.list.set.type],
    addEntityActions: [SgwSettingsActions.set.type],
    setAllIdsAction: SgwSettingsActions.list.set.type,
    setParamsActions: [SgwSettingsActions.list.fetch.type, SgwSettingsActions.list.setParams.type],
    initialParamsState: initialSgwSettingsState.list.table,
  }),
});
