import { initialAppFeatureState } from './store/state';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import { combineReducers } from 'redux';
import { appReducer, generatePayloadReducer } from './common';
import carFreeZones from './common/store/car-free-zones/reducers';
import { errorsReducer } from './common/store/errors/errors.reducer';
import moov from './common/store/moov/reducers';
import ServicesReducer from './common/store/services/reducers';
import { userReducer } from './common/store/user/reducers/user.reducer';
import { utilityCompaniesReducer } from './store/reducers/utilityCompanies.reducer';
import { workOrderItemsReducer } from './common/store/work-order-item/reducers/work-order-items-reducer';
import { cpaReducer } from './cpa';
import { notificationReducer } from './notification/store/notification.reducers';
import { planningReducer } from './planning';
import { eventsReducer, modulesReducer, signsReducer } from './signs';
import { AppFeatureAction, SnackBarActions } from './store/actions';
import { metadataReducer } from './store/reducers/metadata/metadata.reducer';
import { requestsReducer } from './store/reducers/requests/requests.reducer';
import { sgwReducer } from './store/reducers/sgw';
import storage from './store/reducers/storage';
import { reorderingReducer } from './tour-reordering/store/reordering.reducer';
import { settingsReducer } from './store/reducers/settings/settings.reducer';
import { baseApi } from './store/api/rtk-query';

export const reducer = (history: History) =>
  combineReducers({
    app: appReducer,
    carFreeZones,
    cpa: cpaReducer,
    errors: errorsReducer,
    events: eventsReducer,
    modules: modulesReducer,
    moov,
    notifications: notificationReducer,
    planning: planningReducer,
    reordering: reorderingReducer,
    requests: requestsReducer,
    router: connectRouter(history),
    services: ServicesReducer,
    signs: signsReducer,
    snackBar: generatePayloadReducer([SnackBarActions.setFeedback.type], null),
    storage,
    user: userReducer,
    utilityCompanies: utilityCompaniesReducer,
    workOrderItems: workOrderItemsReducer,
    sgw: sgwReducer,
    metadata: metadataReducer,
    appFeatures: generatePayloadReducer([AppFeatureAction.set.type], initialAppFeatureState),
    settings: settingsReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  });
