import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler } from '../../common';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { ICall, ISelect } from '../../types';
import { NewsflashActions } from '../actions';
import { NewsflashApi } from '../api';
import { getPagedApiParams } from '../selectors/newsflash.selectors';
import { push } from 'connected-react-router';
import { appUrls } from '../../common/config/url.constants';

function* onFetchNewsflashList() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const response: ICall<typeof NewsflashApi.fetchList> = yield call(NewsflashApi.fetchList, params!);
  yield all([
    put(
      NewsflashActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
    put(NewsflashActions.list.set(response!.data.data)),
  ]);
}

function* onFetch({ payload }: ReturnType<typeof NewsflashActions.fetch>): SagaIterator {
  const response: ICall<typeof NewsflashApi.fetch> = yield call(NewsflashApi.fetch, payload);
  yield put(NewsflashActions.list.set([response!.data.data]));
}

function* onSave({ payload }: ReturnType<typeof NewsflashActions.save>): SagaIterator {
  const response: ICall<typeof NewsflashApi.fetch> = yield call(NewsflashApi.save, payload);
  yield put(NewsflashActions.list.set([response!.data.data]));
  yield put(push(appUrls.settings.newsflashes.detail(`${response!.data.data.id}`)));
}

export function* newsflashSaga(): SagaIterator {
  yield takeLatest(NewsflashActions.list.fetch.type, genericErrorHandler(onFetchNewsflashList));
  yield takeLatest(NewsflashActions.fetch.type, genericErrorHandler(onFetch));
  yield takeLatest(NewsflashActions.save.type, genericErrorHandler(onSave));
}
